@font-face {
    font-family: "hind-regular";
    src: url('hind-regular.ttf');
}

@font-face {
    font-family: "ibm-plex-sans-regular";
    src: url('ibm-plex-sans-regular.ttf');
}

@font-face {
    font-family: "oswald-regular";
    src: url('oswald-regular.ttf');
}

@font-face {
    font-family: "oswald-normal";
    src: url('oswald-medium.ttf');
}

@font-face {
    font-family: "chakra-petch-bold";
    src: url('ChakraPetch-Bold.ttf');
}
