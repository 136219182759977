.custom-schedule td, th {
  display: block !important;
}

.custom-schedule table {
  background-color: #0E0B0B;
  border-left: 1px solid #2B2929 !important;
  table-layout: fixed;
}

.custom-schedule table tr > * {
  display: block !important;
}

.custom-schedule table tr {
  display: table-cell !important;
}

.custom-schedule td, th {
  border-bottom: 1px solid #2B2929 !important;
  border-right: 1px solid #2B2929 !important;
}

.custom-schedule .instant {
  opacity: 0.8 !important;
  background-color: #DF6E06 !important;
}

.custom-schedule .standard {
  background-color: #0E0B0B !important;
}

.custom-schedule .sleep {
  opacity: 0.8 !important;
  background-color: #939090 !important;
}
