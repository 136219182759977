
.video-js .vjs-slider .vjs-play-progress {
    background-color: #F89021 !important;
}

.video-js .vjs-play-progress:before {
    color: #F89021 !important;
}

.video-js .vjs-volume-level {
    color: #F89021 !important;
    background-color: #F89021 !important;
}
.vjs-poster {
  pointer-events: none !important;
}
.vjs-big-play-centered .vjs-big-play-button {
  margin-top: -0.625em;
  margin-left: -0.625em;
  font-size: 5em;
  width: 1.25em;
  height: 1.25em;
  line-height: 1.25em;
  border: none;
  border-radius: 2em;
}

.video-js .vjs-load-progress div {
  background-color: #4B4B4B;
}

.video-js .vjs-volume-bar.vjs-slider-bar.vjs-slider.vjs-slider-horizontal {
  background-color: #4B4B4B;
}

:focus {
  outline-color: #4B4B4B;
}

.video-js .vjs-big-play-button {
  z-index: 999;
}

.video-js .vjs-control-bar {
  z-index: 999;
}

.vjs-poster {
  z-index: 1;
}
